import { QuizConfig } from '@app/store/quiz/models/QuizConfig';

export const quizConfigMap: Record<string, () => Promise<QuizConfig>> = {
  'quiz67.1': () => import('./quiz-67-1').then(m => m.config),
  quiz133: () => import('./quiz-133').then(m => m.config),
  quiz134: () => import('./quiz-134').then(m => m.config),
  quiz135: () => import('./quiz-135').then(m => m.config),
  'quiz133.1': () => import('./quiz-133.1').then(m => m.config),
  'quiz134.1': () => import('./quiz-134.1').then(m => m.config),
  'quiz135.1': () => import('./quiz-135.1').then(m => m.config),
  quiz136: () => import('./quiz-136').then(m => m.config),
};
